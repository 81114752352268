"use client";

import { useState, useEffect, useRef } from "react";
import { useStore } from "../Store";

import {
  Stack,
  Button,
  Textarea,
  Text,
  Card,
  Group,
  Container,
  Loader,
  ScrollArea,
  Image,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import Markdown from "react-markdown";
import {
  IconArrowUp,
  IconCheck,
  IconAlertTriangleFilled,
  IconWorld,
  IconBooks,
  IconBook,
} from "@tabler/icons-react";
import { client } from "../api/axiosClient";
import { notifications } from "@mantine/notifications";

// const StreamComponent = ({ url }) => {
//   const [fullText, setFullText] = useState("");
//   const [visibleChars, setVisibleChars] = useState(0);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     let isMounted = true;
//     const controller = new AbortController();
//     const signal = controller.signal;

//     const fetchData = async () => {
//       setLoading(true);
//       setError(null);
//       setFullText("");
//       setVisibleChars(0);

//       try {
//         const response = await fetch(url, { signal });

//         if (!response.body) {
//           throw new Error("ReadableStream not yet supported in this browser.");
//         }

//         const reader = response.body
//           .pipeThrough(new TextDecoderStream())
//           .getReader();

//         while (true) {
//           const { value, done } = await reader.read();
//           if (done) break;
//           if (isMounted) {
//             setFullText((prev) => prev + value);
//           }
//         }
//         setLoading(false);
//       } catch (error) {
//         if (error.name !== "AbortError" && isMounted) {
//           setError(error);
//           setLoading(false);
//         }
//       }
//     };

//     fetchData();

//     return () => {
//       isMounted = false;
//       controller.abort();
//     };
//   }, [url]);

//   useEffect(() => {
//     if (visibleChars < fullText.length) {
//       const timer = setTimeout(() => {
//         setVisibleChars((prev) => Math.min(prev + 1, fullText.length));
//       }, 3); // Ajustez ce délai pour contrôler la vitesse d'apparition

//       return () => clearTimeout(timer);
//     }
//   }, [fullText, visibleChars]);

//   const visibleText = fullText.slice(0, visibleChars);

//   return (
//     <ScrollArea h={"70vh"} offsetScrollbars scrollHideDelay={100}>
//       <Card
//         className="ChatCard"
//         withCloseButton={true}
//         size="xl"
//         radius="12px"
//         shadow="lg"
//         withBorder={true}
//       >
//         <Text size="sm" mb="xs" fw={500} ta="left">
//           {loading && (
//             <Group gap="xs">
//               <Image
//                 fit="contain"
//                 src="https://www.iavicenne.fr/static/img/brain_ai_3.png"
//                 alt="iAvicenne Logo"
//                 width={25}
//                 height={25}
//                 style={{ margin: "0", display: "block" }}
//               />
//               <Text fw={700}>Revue de la litterature sur Pubmed</Text>
//               <Loader className="ChatLoader" color="#94ADA7" type="dots" />
//             </Group>
//           )}
//           {error && <p>Error: {error.message}</p>}
//           <Markdown>{visibleText}</Markdown>
//         </Text>
//       </Card>
//     </ScrollArea>
//   );
// };

const StreamComponent = ({ url, wsMessage }) => {
  const [fullText, setFullText] = useState("");

  // Si wsMessage est disponible, on l'utilise comme contenu complet.
  useEffect(() => {
    if (wsMessage) {
      setFullText(wsMessage);
    }
  }, [wsMessage]);

  // (Gardez ici votre logique initiale de streaming depuis 'url' si besoin)
  useEffect(() => {
    if (!url || wsMessage) return; // Si on a déjà un wsMessage, pas besoin d'appeler l'API
    let isMounted = true;
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchData = async () => {
      try {
        const response = await fetch(url, { signal });
        if (!response.body) {
          throw new Error("ReadableStream not supported in this browser.");
        }
        const reader = response.body.pipeThrough(new TextDecoderStream()).getReader();
        while (true) {
          const { value, done } = await reader.read();
          if (done) break;
          if (isMounted) {
            setFullText((prev) => prev + value);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [url, wsMessage]);

  // Effet de "streaming" visuel : apparition progressive des caractères
  const [visibleChars, setVisibleChars] = useState(0);
  useEffect(() => {
    if (visibleChars < fullText.length) {
      const timer = setTimeout(() => {
        setVisibleChars((prev) => Math.min(prev + 1, fullText.length));
      }, 3);
      return () => clearTimeout(timer);
    }
  }, [fullText, visibleChars]);

  const visibleText = fullText.slice(0, visibleChars);

  return (
    <ScrollArea h={"70vh"} offsetScrollbars scrollHideDelay={100}>
      <Card className="ChatCard" withCloseButton size="xl" radius="12px" shadow="lg" withBorder>
        <Text size="sm" mb="xs" fw={500} ta="left">
          <Markdown>{visibleText}</Markdown>
        </Text>
      </Card>
    </ScrollArea>
  );
};

export const PubmedChat = () => {
  const {
    currentNotification,
    setCurrentNotification,
    webVariant,
    scholarVariant,
    pubmedVariant,
    setChat,
    setWebVariant,
    setScholarVariant,
    setPubmedVariant
  } = useStore();

  const [streamUrl, setStreamUrl] = useState("");
  const [taskId, setTaskId] = useState(null);
  const [wsMessage, setWsMessage] = useState("");

  // useEffect(() => {
  //   if (!taskId) return;
  
  //   const ws = new WebSocket("wss://www.iavicenne.fr/ws/tasks/");
  
  //   ws.onopen = () => {
  //     console.log("WebSocket connecté");
  //     // Envoyer le task_id pour s'abonner aux mises à jour de cette tâche
  //     ws.send(JSON.stringify({ type: "subscribe", task_id: taskId }));
  //   };
  
  //   ws.onmessage = (event) => {
  //     try {
  //       const data = JSON.parse(event.data);
  //       // On vérifie que le message correspond bien à notre tâche
  //       if (data.task_id === taskId && data.result) {
  //         setWsMessage(data.result);
  //       }
  //     } catch (error) {
  //       console.error("Erreur lors du parsing du message WebSocket", error);
  //     }
  //   };
  
  //   ws.onerror = (error) => {
  //     console.error("Erreur WebSocket:", error);
  //   };
  
  //   ws.onclose = () => {
  //     console.log("WebSocket déconnecté");
  //   };
  
  //   return () => {
  //     ws.close();
  //   };
  // }, [taskId]);
  
  

  const form = useForm({
    initialValues: {
      message: "",
    },
    validate: {
      message: (value) => (value.length < 3 ? "Votre message est vide" : null),
    },
  });
  const messagesContainerRef = useRef(null);

  const scrollToBottom = () => {
    messagesContainerRef.current?.scrollTo({
      top: messagesContainerRef.current.scrollHeight,
      behavior: "smooth",
    });
  };

// // Fonction de polling pour interroger le statut de la tâche
// const pollTaskStatus = async (taskId) => {
//   try {
//     const response = await client.get(`api/chat/reviewPubmed/get_task_status/?task_id=${taskId}`);
//     const { state, error, result } = response.data; // on suppose que la réponse contient 'state', et éventuellement 'error' et 'result'
//     console.log(`Statut de la tâche (${taskId}): ${state}`);

//     if (state === "SUCCESS") {
//       // La tâche est terminée avec succès, on renvoie le résultat
//       return result;
//     } else if (state === "FAILURE") {
//       // La tâche a rencontré une erreur
//       throw new Error(`La tâche a échoué : ${error}`);
//     } else {
//       // La tâche est encore en attente, on attend 2 secondes avant de re-poller
//       await new Promise(resolve => setTimeout(resolve, 10000));
//       return await pollTaskStatus(taskId);
//     }
//   } catch (err) {
//     throw err;
//   }
// };

  // Votre fonction handleSubmit modifiée
const handleSubmit = async (value) => {
  try {
    if (value.message) {
      // Lancer la tâche via l'API (qui renvoie { task_id: ... })
      // const response = await client.get(`api/chat/reviewPubmed/?message=${encodeURIComponent(value.message)}`);

      // if (response.status === 202 && response.data.task_id) {
      //   const taskId = response.data.task_id;
      //   setTaskId(taskId);
      //   console.log("Tâche lancée :", response.data);

      //   // Interroger régulièrement le statut de la tâche jusqu'à obtenir SUCCESS ou ERROR
      //   const result = await pollTaskStatus(taskId);
      //   console.log("Tâche terminée avec succès :", result);
      //   const response2 = await client.get(`api/chat/webSearch/?message=${encodeURIComponent(value.message)}&search_id=${taskId}`);
    //   } else {
    //     console.error("Erreur lors du lancement de la tâche.");
    // }
    setStreamUrl(
      `https://www.iavicenne.fr/api/chat/reviewPubmed/?message=${value.message}`
    );
    }
  } catch (error) {
    handleNotificationError("handle Transcription", error);
  }
};

  const updateNotification = (title, loading = true) => {
    notifications.update({
      id: currentNotification,
      withBorder: true,
      loading: false,
      radius: "lg",
      color: "#94ADA7",
      title: (
        <Stack>
          <Group gap="xs">
            <Image
              fit="contain"
              src="https://www.iavicenne.fr/static/img/brain_ai_3.png"
              alt="iAvicenne Logo"
              width={25}
              height={25}
              style={{ margin: "0", display: "block" }}
            />
            <Text fw={700}>{title}</Text>
            {loading ? (
              <Loader className="ChatLoader" color="#94ADA7" type="dots" />
            ) : (
              <IconCheck
                style={{ width: "1.1rem", color: "#94ADA7", height: "1.1rem" }}
              />
            )}
          </Group>
        </Stack>
      ),
      opacity: 0.7,
      autoClose: false,
      withCloseButton: true,
    });
  };

  const handleNotificationError = (context, response) => {
    notifications.update({
      id: currentNotification,
      withBorder: true,
      loading: false,
      radius: "lg",
      color: "#94ADA7",
      title: (
        <Group gap="xs">
          <IconAlertTriangleFilled color="red" size="25" />
          <Text fw={700}>Erreur</Text>
          <Text size="sm">
            {context}: {response.status} {response.statusText}
          </Text>
        </Group>
      ),
      opacity: 0.7,
      autoClose: 2000,
      withCloseButton: true,
    });
  };

  return (
    <>
      <Stack>
        <Container className="ChatStreamContainer" ref={messagesContainerRef}>
        {streamUrl && <StreamComponent url={streamUrl} />}

          <form
            className="ChatForm"
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(form.values);
            }}
          >
            <Textarea
              className="ChatTextarea"
              variant="default"
              radius="12px"
              shadow="lg"
              label=""
              placeholder="Saisissez votre Question Médicale ici...(Recherche Pubmed)"
              {...form.getInputProps("message")}
              autosize
              minRows={5}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault();
                  handleSubmit(form.values);
                }
              }}
            />
             <Button
              className="ChatSubmit"
              size="xs"
              variant={pubmedVariant}
              color="#94ADA7"
              radius="12px"
              shadow="lg"
              onClick={() => {
                setChat("showAudioDirectChat");
                setWebVariant("light");
                setScholarVariant("light");
                setPubmedVariant("light");
              }}
              style={{
                position: "absolute",
                right: "220px",
                top: "20%",
                transform: "translateY(-50%)",
              }}
            >
              <IconBooks/>
            </Button>
            <Button
              className="ChatSubmit"
              size="xs"
              variant={scholarVariant}
              color="#94ADA7"
              radius="12px"
              shadow="lg"
              onClick={() => {
                setChat("showScholarChat");
                setWebVariant("light");
                setScholarVariant("filled");
                setPubmedVariant("light");
              }}
              style={{
                position: "absolute",
                right: "150px",
                top: "20%",
                transform: "translateY(-50%)",
              }}
            >
              <IconBook/>
            </Button>
            <Button
              className="ChatSubmit"
              size="xs"
              variant={webVariant}
              color="#94ADA7"
              radius="12px"
              shadow="lg"
              onClick={() => {
                setChat("showAudioChat");
                setWebVariant("filled");
                setScholarVariant("light");
                setPubmedVariant("light");
              }}
              style={{
                position: "absolute",
                right: "80px",
                top: "20%",
                transform: "translateY(-50%)",
              }}
            >
              <IconWorld />
              </Button>
            <Button
              className="ChatSubmit"
              type="submit"
              radius="12px"
              color="#94ADA7"
              size="xs"
              // onClick={handleSubmit}
              style={{
                position: "absolute",
                right: "10px",
                top: "20%",
                transform: "translateY(-50%)",
              }}
              disabled={!form.values.message.trim()}
            >
              <IconArrowUp />
            </Button>
          </form>
        </Container>
      </Stack>
    </>
  );
};
