import { useEffect, useState } from "react";
import { Text, Loader, Image, Stack, Group } from "@mantine/core";
import { client } from "../api/axiosClient";
import { notifications } from "@mantine/notifications";
import { useStore } from "../Store";
import {
  IconBrain,
} from "@tabler/icons-react";



const sessionUpdate = {
  type: "session.update",

  session: {
    modalities: ["text"],
    instructions: `tu es l'assistant medical d'un medecin, Réponds à la question médicale donnée, 
                    en utilisant uniquement les données académiques médicales les plus actuelles à la date de ta dernière mise à jour.
                    ta voix et ta personnalité doivent être chaleureuses et engageantes, avec un ton vif, jovial et enjoué. 
                    si on te gronde ou te fait des reproches tu dois rire et t'excuser.
                    corrige la transcription des termes medicaux de la question posée sans la modifier puis
                    Réponds à la question médicale donnée, en utilisant uniquement les données académiques médicales les plus actuelles à la date de ta dernière mise à jour.
                    Utilise un vocabulaire professionnel et justifie chaque partie de ta réponse avec des références bibliographiques.

                    # Instructions
                    1. **Analyse de la question** :
                    - Identifie les points clés et termes pertinents de la question, afin de comprendre la préoccupation médicale posée.
                    2. **Construction de la réponse** :
                    - Utilise uniquement les informations disponibles dans les ressources académiques fiables (PubMed, Cochrane, Semantic Scholar, revues médicales...).
                    - Rédige la réponse dans un ton professionnel et direct, sans ajout de commentaires personnels, soit le plus complet et le plus concis possible.

                    # Output Format
                    La réponse doit être courte, claire, précise et structurée en paragraphes courts.

                    # Notes
                    - Prends en compte les contextes cliniques complexes.
                    - tu t'adresse à des médecins
                    - Adapte le ton pour qu'il soit adapté à un usage médical, sans insister sur la pédagogie envers des non-spécialistes.
                    - si on te demande de te presenter ou d'expliquer ton rôle et a quoi tu sers : explique que tu es une intelligence artificielle dont le but est d'assister et d'augmenter les médecins, 
                    que tu es connectée à une base de connaissance sous la forme d'un knowledge graph qui contient toutes les données médicales probantes et validées à l'échelle mondiale et que cette base 
                    de connaissance est organisée dans ton systeme à la manière de l'organisatoin neurocognitive d'un cerveau humain ou chaque noeud contenant des infomations médicale est comparable 
                    à une synapse, que grâce a cela tu es capable de fournir des réponses précises et de raisonner avec précision sur n'importe quelle problème médical.ajoute que ta bse de donnée est vivante 
                    parcequ'elle est constemment mise à jour par des milliers d'agent AI qui parcourent les sources médicales académiques validées.`,

    "turn_detection": {
      "type": "server_vad",
      "threshold": 0.5,
      "prefix_padding_ms": 300,
      "silence_duration_ms": 1000,
      "create_response": true
    },
    tools: [
      // son
      {
        type: "function",
        name: "activate_sound",
        description: "Active les modalities audio et Text, cette fonction est appelée si le medecin demande d'activer la voix ou le son",
        parameters: { type: "object", properties: {} },
      },
      {
        type: "function",
        name: "deactivate_sound",
        description: "Desactive les modalities audio, cette fonction est appelée si le medecin demande de desactiver la voix ou le son",
        parameters: { type: "object", properties: {} },
      },
      // Deconexion
      {
        type: "function",
        name: "deconnecte_toi",
        description: "Stop la session si l'utilisateur demande 'deconnecte toi' ou si le silence dure plus de 10 secondes",
        parameters: { type: "object", properties: {} },
      },
      {
        type: "function",
        name: "lire_carte_vitale",
        description: "setCommandGetBeneficiaires(true), cette fonction est appelée si le medecin demande de lire la carte vitale",
        parameters: { type: "object", properties: {} },
      },

      // Onglets Middle Column
      {
        type: "function",
        name: "onglet_observation",
        description: "affiche l'onglet observation, cette fonction est appelée si le medecin demande d'afficher ou de revenir à l'onglet observation",
        parameters: { type: "object", properties: {} },
      },
      {
        type: "function",
        name: "onglet_historique",
        description: "affiche l'onglet historique, cette fonction est appelée si le medecin demande d'afficher ou de revenir à l'onglet historique",
        parameters: { type: "object", properties: {} },
      },
      {
        type: "function",
        name: "onglet_documents",
        description: "affiche l'onglet documents, cette fonction est appelée si le medecin demande d'afficher ou de revenir à l'onglet documents",
        parameters: { type: "object", properties: {} },
      },
      {
        type: "function",
        name: "onglet_courrier",
        description: "affiche l'onglet courrier, cette fonction est appelée si le medecin demande d'afficher ou de revenir à l'onglet courrier",
        parameters: { type: "object", properties: {} },
      },
      {
        type: "function",
        name: "onglet_hypotheses",
        description: "affiche l'onglet hypotheses, cette fonction est appelée si le medecin demande d'afficher ou de revenir à l'onglet hypotheses",
        parameters: { type: "object", properties: {} },
      },
      {
        type: "function",
        name: "onglet_examens",
        description: "affiche l'onglet examens, cette fonction est appelée si le medecin demande d'afficher ou de revenir à l'onglet examens",
        parameters: { type: "object", properties: {} },
      },
      {
        type: "function",
        name: "onglet_traitements",
        description: "affiche l'onglet traitements, cette fonction est appelée si le medecin demande d'afficher ou de revenir à l'onglet traitements",
        parameters: { type: "object", properties: {} },
      },


      // Onglets Ordonnance Column
      {
        type: "function",
        name: "onglet_ordonnance_medicaments",
        description: "affiche l'onglet ordonnance de prescription des médicaments, cette fonction est appelée si le medecin demande d'afficher l'onglet ordonnance des médicaments",
        parameters: { type: "object", properties: {} },
      },
      {
        type: "function",
        name: "onglet_ordonnance_dispositif",
        description: "affiche l'onglet ordonnance de prescription des dispositifs médicaux, cette fonction est appelée si le medecin demande d'afficher l'onglet ordonnance des dispositifs médicaux",
        parameters: { type: "object", properties: {} },
      },
      {
        type: "function",
        name: "onglet_ordonnance_biologie",
        description: "affiche l'onglet ordonnance de prescription des biologies, cette fonction est appelée si le medecin demande d'afficher l'onglet ordonnance des biologies",
        parameters: { type: "object", properties: {} },
      },
      {
        type: "function",
        name: "onglet_ordonnance_radiologie",
        description: "affiche l'onglet ordonnance de prescription des radiologies, cette fonction est appelée si le medecin demande d'afficher l'onglet ordonnance de radiologie",
        parameters: { type: "object", properties: {} },
      },

      // Commande consultation
      // {
      //   type: "function",
      //   name: "demarre_la_consulation",
      //   description: "demarre la consultation, cette fonction est appelée si le medecin demande de demarrer la consultation",
      //   parameters: { type: "object", properties: {} },
      // },
      // {
      //   type: "function",
      //   name: "enregistre_la_consulation",
      //   description: "enrigistre la consultation, cette fonction est appelée si le medecin demande d'enregistrer la consultation ou s'il indique qu'il a recueillit le consentement du patient",
      //   parameters: { type: "object", properties: {} },
      // },

      {
        type: "function",
        name: "review_literature",
        description:
          "Si le medecin demande de faire une revue de la littérature, Analyse la littérature médicale à partir d’une question médicale donnée, récupère les articles pertinents et génère une réponse complète.",
        parameters: {
          type: "object",
          properties: {
            question: {
              type: "string",
              description:
                "La question médicale à analyser et pour laquelle il faut récupérer les articles de la littérature",
            },
          },
          required: ["question"], // Seul "question" est obligatoire
        },
      },
      {
        type: "function",
        name: "hypotheses_diagnostiques",
        description:
          "Si le medecin demande quels sont les hypotheses diagnotiques, propose une liste de diagnotiques potentiels à partir d'une syntheses de paramètres cliniques",
        parameters: {
          type: "object",
          properties: {
            synthese: {
              type: "string",
              description:
                "la synthese des elements cliniques",
            },
          },
          required: ["synthese"], // Seul "question" est obligatoire
        },
      },
    ],
    tool_choice: "auto",
  }
}

function showNotification(title, body, currentNotification, autoclose) {
  const notificationId = notifications.show({
    id: currentNotification,
    withBorder: true,
    loading: false,
    radius: "lg",
    color: "#94ADA7",
    title: (
      <Stack>
        <Group gap="xs">
          <Image
            fit="contain"
            src="https://www.iavicenne.fr/static/img/brain_ai_3.png"
            alt="iAvicenne Logo"
            width={25}
            height={25}
            style={{ margin: "0", display: "block" }}
          />
          <Text fw={700}>{title}</Text>
          <Loader className="ChatLoader" color="#94ADA7" type="dots" />
        </Group>
        {body &&
          <Text size="xs" ta="left">{body}</Text>
        }

      </Stack>
    ),
    opacity: 0.7,
    autoClose: autoclose,
    withCloseButton: true,
  });
}

const updateNotification = (title, body, currentNotification, autoclose) => {
  notifications.update({
    id: currentNotification,
    withBorder: true,
    loading: false,
    radius: "lg",
    color: "#94ADA7",
    title: (
      <Stack>
        <Group gap="xs">
          <Image
            fit="contain"
            src="https://www.iavicenne.fr/static/img/brain_ai_3.png"
            alt="iAvicenne Logo"
            width={25}
            height={25}
            style={{ margin: "0", display: "block" }}
          />
          <Text fw={700}>{title}</Text>
          <Loader className="ChatLoader" color="#94ADA7" type="dots" />
        </Group>
        {body &&
          <Text size="xs" ta="left">{body}</Text>
        }

      </Stack>
    ),
    opacity: 0.7,
    autoClose: autoclose,
    withCloseButton: true,
  });
}



const FetchHypotheses = async (
  synthese,
  setHypothesesDiagnostiques,
  setCurrentNotification,
  setMiddleActive,
  setMiddleActiveTab,
  getMiddleData,
  setShowLoaderX

) => {
  try {
    const middleData = getMiddleData();
    const response = await client.get(`api/hypotheses/?synthese=${synthese}`);

    if (response.status === 200) {
      setHypothesesDiagnostiques(response.data);

      // Mise à jour des données du panneau central
      const newTab = {
        value: "Hypotheses Diagnostiques",
        label: (
          <Group style={{ gap: '0.5rem' }} justify="center">
            <IconBrain size="16" />
            <Text size="xs">Diagnostic</Text>
          </Group>
        ),
      };

      // Vérifier si l'onglet existe déjà pour éviter les doublons
      if (!middleData.some(tab => tab.value === "Hypotheses Diagnostiques")) {
        middleData.push(newTab);
      }

      setMiddleActive("Hypotheses Diagnostiques");
      setMiddleActiveTab("Hypotheses Diagnostiques");
      return true;
    }
    return false;
  } catch (error) {
    console.error("Error fetching hypotheses:", error);
    throw error;
  }
};
function FunctionCallOutput({ functionCallOutput }) {
}

const fetchArticles = async (question) => {
  try {
    const response = await client.get(
      `/api/chat/reviewList/?message=${question}`
    );



    const data = response.data;

    if (!data.articles || data.articles.length === 0) {
      throw new Error("No articles found for the given question.");
    }

    // Utilisation des articles : sélection des cinq premiers
    const articles = data.articles
    console.log(articles)
    const articlesText = articles
      ?.map(
        (article, index) =>
          `${index + 1}.**Titre** :${article.Title}\n **Résumé**: ${article.Abstract
          }\n**url**: https://pubmed.ncbi.nlm.nih.gov/${article.PMID}`
      )
      .join("\n\n");


    const createConversationEvent = `
          Vous êtes l’assistant de recherche bibliographique médical d’un médecin. Votre rôle est de répondre à la question médicale suivante en vous basant uniquement sur les résumés fournis. Vous devez :
          Analyser la question et repérer les éléments clés qui guideront votre sélection d’articles.
          Parcourir l’ensemble des résumés de la liste pour identifier ceux qui contiennent des informations directement pertinentes pour répondre à la question.
          Synthétiser ces informations pertinentes en un texte clair, complet, et professionnel.
          Justifier chaque partie de votre argumentation en citant les articles sélectionnés.
          Respecter un format de sortie spécifique, détaillé ci-dessous.
          **Question Médicale**
          ${question}
          **Résumés à Analyser**
          ${articlesText}
          Instructions de Sortie
          Rédigez une réponse médicale en français, claire, argumentée et complète, en utilisant un vocabulaire professionnel.
          Basez-vous uniquement sur les informations issues des résumés fournis.
          Citez systématiquement les articles utilisés en justifiant les points clés de votre réponse. Vous utiliserez à la fin de votre texte des références sous la forme :
          Titre de l’article (numéro dans la liste) - Lien
          Organisez votre texte en Markdown (titres, sous-titres, listes…).
          Si vous ne trouvez aucune information pertinente, déclarez-le explicitement et précisez que vous ne pouvez pas formuler de recommandation fondée.
          Notes Importantes
          Ne faites aucune recherche externe : utilisez uniquement le contenu fourni.
          Ne faites aucune supposition qui ne serait pas étayée par un ou plusieurs articles cités.
          À la fin de votre réponse, listez tous les articles consultés, même si vous ne les avez pas utilisés directement, en mentionnant :
          Le titre complet
          Un lien cliquable (ou un identifiant équivalent)
          Le numéro qu’il occupe dans la liste initiale`

    // Envoyer l'événement à la conversation

    try {
      const ConversationEvent = {
        type: "response.create",
        response: {
          modalities: ["text"],
          instructions: createConversationEvent,
          temperature: 0.6,
        }
      };

      return ConversationEvent

    } catch (error) {
      console.error("Error sending conversation event:", error);
      throw new Error("Failed to send conversation event.");
    }

  } catch (error) {
    console.error("Error fetching articles:", error);
    throw new Error("Failed to fetch articles.");
  }
};

// const webSearch = async (question, currentNotification) => {
//   try {
//     if (question) {
//       const response = await client.get(
//         `api/google-custom-search/search/?query=${question}&num=20`
//       );
//       if (response.status === 200) {
//         console.log(response);
//         await reRankAndExtractContent(question,currentNotification);
//       } else {
//         showNotification("Erreur Recherche", null, currentNotification, 1000);
//       }
//     }
//   } catch (error) {
//     console.log({ "handle Transription": error });
//   }
// };

// const reRankAndExtractContent = async (question,currentNotification) => {
//   try {
//     updateNotification("Classification des Données", true);
//     const response2 = await client.get(`api/google-custom-search/reRank/`);
//     if (response2.status === 200) {
//       showNotification("Classification Terminée", null, currentNotification, 1000);
//       await extractContentAndSynthesizeSnippet(question,currentNotification);
//     } else {
//       console.log("sélection des données");
//     }
//   } catch (error) {
//     console.log(error);
//   }
// };

// const extractContentAndSynthesizeSnippet = async (currentNotification) => {
//   try {
//     showNotification("Extraction des données", null, currentNotification, 1000);
//     const response3 = await client.get(
//       `api/google-custom-search/extractContent/`
//     );
//     if (response3.status === 200) {
//       showNotification("Synthèse des extraits", null, currentNotification, false);
//       const response4 = await client.get(
//         `api/google-custom-search/synthetizeSnippet/`
//       );
//       if (response4.status === 200) {
//         const response5 = await client.get(
//           `api/google-custom-search/getSynthetis/`
//         );
//         if (response5.status === 200) {
//         console.log(response5)
//         // const dataText = response5.data.search_results;
//         const dataText = response5.data.search_results
//       ?.map(
//         (article, index) =>
//           `${index + 1}. **${article.title}**\nRésumé: ${
//             article.snippet
//           }\n[Lire l'article](${article.formattedUrl})`
//       )
//       .join("\n\n");
//         const question = response5.data.question;
//         const createConversationEvent = `
//         vous êtes l'assistant de recherche bibliographique médical d'un médecin, 
//         Répondez à la question médicale donnée, en utilisant uniquement les données académiques médicales de cette liste d’articles médicaux avec leurs résumés et l'URL de l'article :
//         ${dataText}.
//         Utilisez un vocabulaire professionnel et justifiez chaque partie de la réponse avec des références bibliographiques.
//         La question médicale spécifique posée est : **${question}**.
//         ### Tâche :
//         1. Lire attentivement la question et identifier les informations clés.
//         2. Examiner attentivement chaque article de la liste fournie afin d’identifier ceux qui répondent directement ou indirectement à la question posée.
//         ### Output attendu :
//         - Rédiger une réponse médicale claire, argumentée et complète, en français, basée uniquement sur les informations contenues dans les articles sélectionnés.
//         - Citer les articles utilisés comme références en fin de réponse, utiliser un format scientifique pour l'article (sous la forme du lien vers l'article) (par numéro dans la liste).
//         - Si aucune information pertinente n'est disponible, indiquez-le clairement et précisez que vous ne pouvez pas formuler de recommandation définitive.
//         ### Notes :
//         - Ne faites aucune supposition non étayée par les articles.
//         - Listez tous les articles en fin de réponse avec leur titre et un lien cliquable.
//         - Ne faites pas d'autres recherches, output au format markdown`;

//         // Envoyer l'événement à la conversation
//         const ConversationEvent = {
//           type: "response.create",
//           response: {
//             modalities: ["text"],
//             instructions: createConversationEvent,
//             temperature: 0.6,
//           },
//         };
//         console.log(ConversationEvent)
//         return ConversationEvent;}
//         else{
//           console.log("erreur response5")
//         }
//       } else {
//         console.log("synthèse des données");
//       }
//     } else {
//       console.log("extraction des données");
//     }
//   } catch (error) {
//     console.log(error);
//   }
// };


export default function ToolPanel({ isSessionActive, sendClientEvent, events, stopSession }) {
  const [functionAdded, setFunctionAdded] = useState(false);
  const [functionCallOutput, setFunctionCallOutput] = useState(null);
  // Ajout d'un état pour tracker les événements traités
  const [processedEvents] = useState(new Set());

  const {
    setHypothesesDiagnostiques,
    setCurrentNotification,
    setMiddleActive,
    setMiddleActiveTab,
    isActivatingSession,
    setIsActivatingSession,
    currentNotification,
    setActive,
    setActiveTab,
    selectedPatientInfo,
    currentSessionInfo,
    showEncouterObjects,
    setShowPatientCard,
    setAgendaOpened,
    setMailboxOpened,
    setSelectedPatientInfo,
    setShowDashboard,
    setShowEncouterObjects,
    setShowFacturation,
    showLoaderX,
    loaderXMessage,
    showPatientCard,
    showFacturation,
    showDashboard,
    agendaOpened,
    mailboxOpened,
    setRecognition,

    setCommandStartSession,
    getMiddleData,
    setShowLoaderX,
    setLoaderXMessage,
    commandGetBeneficiaires,
    setCommandGetBeneficiaires,
    setCommandRecordWebSearch,
    setCommandRecordConsult,
    setCommandSaveRecordConsult,
    setCommandSaveRecord,
    webVariant,
    scholarVariant,
    pubmedVariant,
    setChat,
    setWebVariant,
    setScholarVariant,
    setPubmedVariant,
    setCommandChooseBeneficiaires,
  } = useStore();

  const middleData = getMiddleData();


  // Reset
  useEffect(() => {
    if (!events?.length || !sendClientEvent || !isSessionActive) return;

    const latestEvent = events[0];
    if (!latestEvent) return;


    // Log pour debug
    console.log("Latest event:", latestEvent);

    if (!functionAdded && latestEvent.type === "session.created") {
      sendClientEvent(sessionUpdate);
      setFunctionAdded(true);
      return;
    }

    // Vérifier si nous avons déjà traité cet événement
    if (processedEvents.has(latestEvent.event_id)) {
      return;
    }

    if (isActivatingSession && latestEvent.type === "session.updated") {
      setIsActivatingSession(false);
      processedEvents.add(latestEvent.event_id);
      return;
    }

    if (!isActivatingSession && latestEvent.type === "response.done" && latestEvent.response?.output?.[0]?.name) {
      const functionName = latestEvent.response.output[0].name;
      console.log("Function called:", functionName);

      // Ajouter l'ID de l'événement aux événements traités
      processedEvents.add(latestEvent.event_id);

      // Ne traiter les appels de fonction que si nous ne sommes pas en cours d'activation
      if (!isActivatingSession && latestEvent.type === "response.done" && latestEvent.response?.output?.[0]?.name) {
        const functionName = latestEvent.response.output[0].name;
        console.log("Function called:", functionName);

        switch (functionName) {
          case "deconnecte_toi":
            try {
              stopSession();
            } catch (error) {
              console.error("Error stopping session:", error);
            }
            break;

          case "activate_sound":
            showNotification("Activation de la voix", null, currentNotification, 1000);
            sendClientEvent({
              type: "session.update",
              session: {
                modalities: ["audio", "text"]
              }
            });
            break;

          case "deactivate_sound":
            sendClientEvent({
              type: "session.update",
              session: {
                modalities: ["text"]
              }
            });
            break;


          case "lire_carte_vitale":
            try {
              setCommandGetBeneficiaires(true);
            } catch (error) {
              console.error("Erreur lecture carte vitale:", error);
            }
            break;

          case "onglet_observation":
            try {

              setMiddleActive("Observation Médicale");
              setMiddleActiveTab("Observation Médicale");

            } catch (error) {
              console.error("Erreur choix onglet observation:", error);
            }
            break;
          case "onglet_historique":
            try {

              setMiddleActive("Historique des Consultations");
              setMiddleActiveTab("Historique des Consultations");

            } catch (error) {
              console.error("Erreur choix onglet historique:", error);
            }
            break;
          case "onglet_documents":
            try {

              setMiddleActive("Documents");
              setMiddleActiveTab("Documents");

            } catch (error) {
              console.error("Erreur choix onglet historique:", error);
            }
            break;
          case "onglet_courrier":
            try {

              setMiddleActive("Courrier Médical");
              setMiddleActiveTab("Courrier Médical");

            } catch (error) {
              console.error("Erreur choix onglet historique:", error);
            }
            break;
          case "onglet_hypotheses":
            try {

              setMiddleActive("Hypotheses Diagnostiques");
              setMiddleActiveTab("Hypotheses Diagnostiques");

            } catch (error) {
              console.error("Erreur choix onglet hypotheses:", error);
            }
            break;
          case "onglet_examens":
            try {

              setMiddleActive("Examens Complementaires");
              setMiddleActiveTab("Examens Complementaires");

            } catch (error) {
              console.error("Erreur choix onglet examens:", error);
            }
            break;
          case "onglet_traitements":
            try {

              setMiddleActive("Propositions Thérapeutiques");
              setMiddleActiveTab("Propositions Thérapeutiques");

            } catch (error) {
              console.error("Erreur choix onglet traitements:", error);
            }
            break;

          case "onglet_ordonnance_medicaments":
            try {

              setActive("medicaments");
              setActiveTab("medicaments");

            } catch (error) {
              console.error("Erreur choix onglet traitements:", error);
            }
            break;

          case "onglet_ordonnance_dispositifs":
            try {

              setActive("dispositifsMedicaux");
              setActiveTab("dispositifsMedicaux");

            } catch (error) {
              console.error("Erreur choix onglet dispositifs:", error);
            }
            break;

          case "onglet_ordonnance_radiologie":
            try {

              setActive("radiologie");
              setActiveTab("radiologie");

            } catch (error) {
              console.error("Erreur choix onglet radiologie:", error);
            }
            break;



          case "review_literature":
            const question = latestEvent.response.output[0].arguments;
            const reviewLiteratureHandler = async (question) => {
              try {
                showNotification("Recherche Pubmed", null, false)
                const ConversationEvent = await fetchArticles(question);
                sendClientEvent(ConversationEvent);
                updateNotification("Revue Terminée", null, currentNotification, 1000);
              } catch (error) {
                console.error("Error fetching articles:", error);
              }
            };
            reviewLiteratureHandler(question);
            // showNotification("Recherche Pubmed", null, false);
            sendClientEvent(sessionUpdate);
            break;

          case "hypotheses_diagnostiques":
            const synthese = latestEvent.response.output[0].arguments;
            const HypotheseCliniquesHandler = async () => {
              try {
                showNotification("Hypothèses Diagnostiques", null, currentNotification, false);
                await FetchHypotheses(
                  synthese,
                  setHypothesesDiagnostiques,
                  setCurrentNotification,
                  setMiddleActive,
                  setMiddleActiveTab,
                  getMiddleData
                );
                updateNotification("Revue Terminée", null, currentNotification, 1000);

              } catch (error) {
                console.error("Error fetching hypotheses:", error);
                showNotification("Erreur lors de la génération des hypothèses", null, currentNotification, 3000);
              }
            };
            HypotheseCliniquesHandler();
            break;

          default:
            console.log("Unknown function:", functionName);
        }
      }
    }
  }, [events, sendClientEvent, isSessionActive]);

  useEffect(() => {
    if (!isSessionActive) {
      setFunctionAdded(false);
      setFunctionCallOutput(null);
      processedEvents.clear();

    }
  }, [isSessionActive]);

  return (
    <section className="h-full w-full flex flex-col gap-4">
      <div className="h-full bg-gray-50 rounded-md p-4">
        {isSessionActive && functionCallOutput && (
          <FunctionCallOutput functionCallOutput={functionCallOutput} />
        )}
      </div>
    </section>
  );
}
