// App.js

import {
  MantineProvider,
  Stack,
  Button,
  Image,
  Grid,
  Text,
} from "@mantine/core";
import { useEffect } from "react";
import { useFullscreen } from "@mantine/hooks";
import { useAuth } from "./hooks/useAuth";
import { useStore } from "./Store";
import { NavBar } from "./elements/Navbar";
import { AntecedentsColumn } from "./elements/AntecedentsColumn";
import { MiddleColumn } from "./elements/MiddleColumn";
import { OrdonnanceColumn } from "./elements/OrdonnanceColumn";
import { Calendar } from "./components/Calendar";
import { Mailbox } from "./components/mail";
import { Login } from "./components/Login";
import { Notifications } from "@mantine/notifications";
import { PractitionerDashboard } from "./components/Dashboard";
import { LoginComponent } from "./components/Login2FA";
import { FacturationColumn } from "./elements/Facturation";
// import SmartCardReader from "./components/trys/smartcard.js"
import "./App.css";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/notifications/styles.css";

import {
  IconDeviceDesktopUp,
  IconDeviceDesktopDown,
} from "@tabler/icons-react";

export function App() {
  const { toggle, fullscreen } = useFullscreen();
  const { currentUser, loading } = useAuth();
  const {
    showPatientCard,
    showDashboard,
    showEncouterObjects,
    agendaOpened,
    mailboxOpened,
    setRecognition, 
    isSessionActive, 
    setCommandStartSession,
    setShowPatientCard,
    setAgendaOpened,
    setMailboxOpened,
    setShowDashboard,
    getMiddleData,
    setMiddleActive,
    setMiddleActiveTab,
    setShowLoaderX,
    showFacturation,
  } = useStore();
  // const middleData = getMiddleData();

  // useEffect(() => {
  //   if (!("webkitSpeechRecognition" in window)) {
  //     console.error("Speech recognition not supported in this browser.");
  //     return;
  //   }

  //   const recognition = new window.webkitSpeechRecognition();
  //   recognition.continuous = true;
  //   recognition.lang = "fr-FR";

  //   recognition.onresult = (event) => {
  //     const lastResult = event.results[event.results.length - 1];
  //     if (lastResult.isFinal) {
  //       const command = lastResult[0].transcript.trim().toLowerCase();
  //       console.log("Voice Command Detected:", command);
  //       if (command.includes("avicenne") && !isSessionActive) {
  //         setCommandStartSession(true)
  //         setShowPatientCard(true);
  //         setMiddleActive("iAvicenne");
  //         setMiddleActiveTab("iAvicenne");
  //         setAgendaOpened(false);
  //         setMailboxOpened(false);
  //         setShowDashboard(false)
  //         setShowLoaderX(false)
  //       } 
  //     }
  //   };

  //   recognition.onerror = (event) => {
  //     console.error("Speech recognition error:", event.error);
  //   };

  //   recognition.onend = () => {
  //     recognition.start(); // Restart listening
  //   };

  //   recognition.start();

  //   // Stocker l'instance dans le store
  //   setRecognition(recognition);

  //   return () => {
  //     recognition.stop();
  //     recognition.onend = null;
  //   };
  // }, [setRecognition]);

  
  return (
    <MantineProvider>
      <Notifications
        position="top-center"
        zIndex={1000}
        transitionDuration={500}
        containerWidth={370}
        containerHeigth={50}
        notificationMaxHeight={170}
        limit={5}
      />
      <div className="App">
        <Image
          className="ArrierePlan"
          radius="12px"
          src="https://www.iavicenne.fr/static/img/arriere_plan.png"
          fit="contain"
        />
        <Image
          className="ArrierePlanBrain"
          radius="12px"
          src="https://www.iavicenne.fr/static/img/brain_ai_3.png"
          fit="contain"
        />

        {loading === false && currentUser ? (
          <div>
            <Button
              className="fullscreenButton"
              variant="filled"
              onClick={toggle}
              color={fullscreen ? "#94ADA7" : "#94ADA7"}
            >
              {fullscreen ? <IconDeviceDesktopDown /> : <IconDeviceDesktopUp />}
            </Button>
            <Stack>
              <NavBar />
              {showPatientCard && (
                <Grid>
                 <AntecedentsColumn />
                  <MiddleColumn />
                  {showEncouterObjects && <OrdonnanceColumn />}
                  {showFacturation && <FacturationColumn />}
                  
                </Grid>
              )}
              {agendaOpened && <Calendar />}
              {mailboxOpened && <Mailbox />}
              {showDashboard && <PractitionerDashboard/>}
              {/* <SmartCardReader/> */}
            </Stack>
          </div>
        ) : (
          <>
            <Login />
            {/* <LoginComponent/> */}
            <Stack>
              <Text
                className="homeTitle"
                fw={500}
                c="#94ADA7"
                style={{
                  fontSize: 60,
                  fontFamily: "Palatino",
                  position: "absolute",
                }}
              >
                iAvicenne
              </Text>
              <Text
                className="homeTitle-2"
                fw={500}
                c="#94ADA7"
                style={{
                  fontSize: 18,
                  fontFamily: "Palatino",
                  position: "absolute",
                }}
              >
                Changement de Paradigme Médical
              </Text>
            </Stack>
          </>
        )}
      </div>
    </MantineProvider>
  );
}
