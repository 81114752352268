// components/OrdonnanceColumn.js

import "../App.css";
import { useEffect, useState } from "react";
import {
  Grid,
  Tabs,
  SegmentedControl,
  Card,
  Stack,
  Group,
  Title,
  Divider,
  Text,
  Badge,
  Button,
  Tooltip,
  Switch,
  CloseButton,
  TextInput,
  NumberInput,
  PillsInput,
  Pill,
  Select,
  CheckIcon,
  Combobox,
  useCombobox,
  Checkbox,
} from "@mantine/core";
import { DateInput, DatePicker } from "@mantine/dates";
import {
  IconX,
  IconRadioactiveFilled,
  IconDropletFilled,
  IconPillFilled,
  IconBandage,
  IconCheck,
  IconPlus,
} from "@tabler/icons-react";
import { useStore } from "../Store";
import { useToggle } from "@mantine/hooks";
import React from "react";

// import { useState } from "react"; // plus besoin de useState local

export function FacturationColumn() {
  const {
    // Déstructurez votre store :
    activeFacturation,
    activeTabFacturation,
    dataFacturation,
    setActiveFacturation,
    setActiveTabFacturation,

    activeExoneration,
    dataExoneration,
    setActiveExoneration,
    setActiveTabExoneration,
    dataATMP,
    activeATMP,
    setActiveATMP,
    setActiveTabATMP,

    // Toutes les variables / setters pour la Feuille de soins :
    contexte,
    setContexte,
    parcours,
    setParcours,
    setIsMaladie,
    isALD,
    setIsALD,
    isPrev,
    setIsPrev,
    isL115,
    setIsL115,
    accidentTiers,
    setAccidentTiers,
    accidentDate,
    setAccidentDate,
    isMaternite,
    setIsMaternite,
    dateDebutGrossesse,
    setDateDebutGrossesse,
    dateAccouchement,
    setDateAccouchement,
    isATMP,
    setIsATMP,
    numATMP,
    setNumATMP,
    accesDirectSpecifique,
    setAccesDirectSpecifique,
    urgence,
    setUrgence,
    horsResidence,
    setHorsResidence,
    medecinRemplace,
    setMedecinRemplace,
    horsCoordination,
    setHorsCoordination,
    actes,
    setActes,
    montantTotal,
    setMontantTotal,

    // éventuellement d’autres champs...
  } = useStore();

  const handleFacturationControlChange = (value) => {
    setActiveFacturation(value);
    setActiveTabFacturation(value);
  };

  const handleExonerationControlChange = (value) => {
    setActiveExoneration(value);
  };

  const handleATMPControlChange = (value) => {
    setActiveATMP(value);
  };

  // Ajouter une ligne d'acte
  const addActeLine = () => {
    setActes([
      ...actes,
      { dateActe: null, codeActe: "", tarif: 0, deplacement: 0 },
    ]);
  };

  // Mettre à jour un acte existant
  const updateActeLine = (index, field, value) => {
    const newActes = [...actes];
    newActes[index][field] = value;
    setActes(newActes);
  };

  const [selectedItem, setSelectedItem] = useState(null);
  const comboboxContexte = useCombobox({
    onDropdownClose: () => comboboxContexte.resetSelectedOption(),
  });
  const comboboxParcours = useCombobox({
    onDropdownClose: () => comboboxParcours.resetSelectedOption(),
  });
  const dataContexte = [
    "Maladie",
    "Accident du Travail / Maladie Professionnelle",
    "Maternité",
  ];
  const contexteOptions = dataContexte.map((item) => (
    <Combobox.Option value={item} key={item}>
      {item}
    </Combobox.Option>
  ));
  const dataParcours = [
    "T - Médecin Traitant (MT)",
    "R - Médecin traitant de substitution",
    "H - Hors résidence habituelle ",
    "N - MT non déclaré / Nouveau MT",
    "U - Urgence",
    "O - Patient orienté par un MT",
    "M - Patient orienté par un autre médecin que le MT",
    "J - Généraliste récemment installé",
    "B - Médecin installé en zone sous-médicalisée",
    "D - Accès direct spécifique",
    "S - Hors accès direct spécifique / Non-respect du parcours",
    "Exclusion du parcours de soins",
  ];
  const parcoursOptions = dataParcours.map((item) => (
    <Combobox.Option value={item} key={item}>
      {item}
    </Combobox.Option>
  ));

  const [AccidentTiers, toggleAccidentTiers] = useToggle();
  const [AccidentTiersDate, setAccidentTiersDate] = useState("");
  const [TiersAMO, setTiersAMO] = useState(false);
  const [TiersAMC, setTiersAMC] = useState(false);

  useEffect(() => {
    if (activeExoneration !== "Non Exonéré") {
      setTiersAMO(true);
      setTiersAMC(true);
    } else {
      setTiersAMO(false);
      setTiersAMC(false);
    }
  }, [activeExoneration]);
  return (
    <Grid.Col span={4.8} className="ordonnanceGridCol">
      <Divider
        my="xs"
        label="Feuille de Soins & Facturation"
        labelPosition="center"
      />
      <Tabs
        value={activeTabFacturation}
        onChange={setActiveTabFacturation}
        radius="12px"
      >
        <SegmentedControl
          data={dataFacturation}
          value={activeFacturation}
          onChange={handleFacturationControlChange}
          color="#94ADA7"
          radius="12px"
          size="xs"
          withItemsBorders={false}
          transitionDuration={500}
          transitionTimingFunction="linear"
          fullWidth
          style={{ marginBottom: 20 }}
        />

        {/* =======================================
            Onglet Feuille de Soins Électronique
        ========================================== */}
        <Tabs.Panel value="FSE">
          <Card
            className="OrdoMedCard"
            shadow="lg"
            radius="12px"
            p="md"
            withBorder={true}
          >
            <Stack align="stretch" justify="flex-start" gap="xl">
              <Group position="apart" justify="space-between">
                <Title size="h4">Feuille de Soins Électronique</Title>
                <DateInput
                  label={
                    <Text ta="start" c="#94ADA7" fw={700} size="xs">
                      Date des Soins
                    </Text>
                  }
                  variant="filled"
                  valueFormat="DD/MM/YYYY"
                  size="sm"
                  radius="12px"
                  placeholder="Date des Soins"
                  defaultValue={new Date()}
                  maxDate={new Date()}
                  w="5.5vw"
                  //   value={AccidentTiersDate}
                  //   onChange={setAccidentTiersDate}
                />
              </Group>
              {/* <Divider  label="Contexte du Parcours de soins" labelPosition="center" /> */}
              <Group grow>
                <Combobox
                  store={comboboxContexte}
                  width={250}
                  position="bottom-start"
                  withArrow
                  onOptionSubmit={(val) => {
                    setContexte(val);
                    comboboxContexte.closeDropdown();
                  }}
                  comboboxProps={{
                    transitionProps: { transition: "pop", duration: 200 },
                  }}
                >
                  <Combobox.Target>
                    <Button
                        className="antMedButton"
                      variant="outline"
                      radius="12px"
                      color="#94ADA7"
                      onClick={() => comboboxContexte.toggleDropdown()}
                    >
                      {contexte}
                    </Button>
                  </Combobox.Target>

                  <Combobox.Dropdown style={{ width: "17vw" }}>
                    <Combobox.Options>{contexteOptions}</Combobox.Options>
                  </Combobox.Dropdown>
                </Combobox>
                <Combobox
                  store={comboboxParcours}
                  width={250}
                  position="bottom-start"
                  withArrow
                  onOptionSubmit={(val) => {
                    setParcours(val);
                    comboboxParcours.closeDropdown();
                  }}
                  comboboxProps={{
                    transitionProps: { transition: "pop", duration: 200 },
                  }}
                >
                  <Combobox.Target>
                    <Button
                        className="antMedButton"
                      variant="outline"
                      radius="12px"
                      color="#94ADA7"
                      onClick={() => comboboxParcours.toggleDropdown()}
                    >
                      {parcours}
                    </Button>
                  </Combobox.Target>

                  <Combobox.Dropdown style={{ width: "17vw" }}>
                    <Combobox.Options>{parcoursOptions}</Combobox.Options>
                  </Combobox.Dropdown>
                </Combobox>
              </Group>

              {/* ---- Section Maladie/ALD/Prévention/Art L115 ---- */}
              {/* <Text weight={500}>Conditions de prise en charge :</Text> */}
              {contexte === "Maladie" && (
                <>
                  <SegmentedControl
                    data={dataExoneration}
                    value={activeExoneration}
                    onChange={handleExonerationControlChange}
                    color="#94ADA7"
                    radius="12px"
                    size="xs"
                    withItemsBorders={false}
                    transitionDuration={500}
                    transitionTimingFunction="linear"
                    fullWidth
                  />
                  {parcours === "O - Patient orienté par un MT" && (
                    <Group>
                      <TextInput
                        variant="filled"
                        radius="12px"
                        label={
                          <Text ta="start" c="#94ADA7" fw={700} size="sm">
                            Nom
                          </Text>
                        }
                        placeholder="Nom du MT"
                        required
                      />

                      <TextInput
                        variant="filled"
                        radius="12px"
                        label={
                          <Text ta="start" c="#94ADA7" fw={700} size="sm">
                            Prénom
                          </Text>
                        }
                        placeholder="Prénom du MT"
                        required
                      />
                    </Group>
                  )}
                  <Group align="center">
                   
                    <Checkbox
                      variant="filled"
                      radius="12px"
                      color="#94ADA7"
                      iconColor="#fff"
                        m="0.8vh"
                      label={
                        <Text ta="start" c="#94ADA7" fw={700} size="sm">
                          Accident de droit commun ou de la vie privée
                        </Text>
                      }
                      checked={AccidentTiers}
                      onChange={toggleAccidentTiers}
                    />

                    {/* ---- Accident causé par un tiers ---- */}
                    {AccidentTiers && (
                      <DateInput
                        // label={
                        //   <Text ta="start" c="#94ADA7" fw={700} size="xs">
                        //     Date de l'accident
                        //   </Text>
                        // }
                        variant="filled"
                        valueFormat="DD/MM/YYYY"
                        maxDate={new Date()}
                        size="xs"
                        radius="12px"
                        w="6vw"
                        ml="lg"
                        placeholder="Date de l'Accident"
                        defaultValue={new Date()}
                        value={AccidentTiersDate}
                        onChange={setAccidentTiersDate}
                      />
                    )}
                  </Group>
                </>
              )}

              {/* ---- Maternité ---- */}
              {contexte === "Maternité" && (
                <DateInput
                  label={
                    <Text ta="start" c="#94ADA7" fw={700} size="xs">
                      Date du début de Grossesse ou de l'accouchement
                    </Text>
                  }
                  variant="filled"
                  valueFormat="DD/MM/YYYY"
                  size="sm"
                  radius="12px"
                  placeholder="Date du début de Grossesse ou de l'accouchement"
                  defaultValue={new Date()}
                  value={AccidentTiersDate}
                  maxDate={new Date()}
                  onChange={setAccidentTiersDate}
                />
              )}

              {/* ---- AT/MP ---- */}

              {contexte === "Accident du Travail / Maladie Professionnelle" && (
                <>
                  {/* <SegmentedControl
               label="Support d'Acquisition du Numéro de l'Organisme Gestionnaire"
               data={dataATMP}
               value={activeATMP}
               onChange={handleATMPControlChange}
               color="#94ADA7"
               radius="12px"
               size="xs"
               withItemsBorders={false}
               transitionDuration={500}
               transitionTimingFunction="linear"
               fullWidth
             /> */}
                  <Group grow>
                    <DateInput
                      label={
                        <Text ta="start" c="#94ADA7" fw={700} size="xs">
                          Date de l'Accident de Travail
                        </Text>
                      }
                      variant="filled"
                      valueFormat="DD/MM/YYYY"
                      size="sm"
                      radius="12px"
                      placeholder="Date de l'Accident de Travail ou de la Maladie professionnelle"
                      defaultValue={new Date()}
                      maxDate={new Date()}
                      value={AccidentTiersDate}
                      onChange={setAccidentTiersDate}
                    />
                    <NumberInput
                      variant="filled"
                      radius="12px"
                      label={
                        <Text ta="start" c="#94ADA7" fw={700} size="xs">
                          N° de l'AT ou de la MP
                        </Text>
                      }
                      placeholder="Numéro de l'accident de travail"
                      // minLength={13}
                      // maxLength={13}

                      hideControls
                    />
                  </Group>

                  <Checkbox
                    variant="filled"
                    radius="12px"
                    color="#94ADA7"
                    iconColor="#fff"
                    label={
                      <Text ta="start" c="#94ADA7" fw={700} size="sm">
                        Un Feuillet Accident de Travail a été présenté
                      </Text>
                    }
                  />
                </>
              )}

              {/* ---- Actes effectués ---- */}
              <Select
                searchable
                clearable
                placeholder="Rechercher et Ajouter un Acte"
                // data={data}
                allowDeselect={false}
                maxDropdownHeight={400}
                // searchValue={query}
                // onSearchChange={setQuery}
                // value={selectedValue}
                // onChange={(value) => {
                //     const selectedMedicament = data.find((item) => item.value === value);
                //     if (selectedMedicament) {
                //     handleMedicamentClick(selectedMedicament);
                //     setSelectedValue(null);
                //     setQuery("");
                //     setData([]);
                //     }
                // }}
                comboboxProps={{
                  transitionProps: { transition: "pop", duration: 200 },
                  shadow: "lg",
                  radius: "lg",
                }}
                style={{ width: "100%", marginBottom: 20 }}
                leftSection={
                  <IconPlus size="1rem" style={{ display: "block" }} />
                }
                // rightSection={loading && <Loader size="sm" />}
                // renderOption={(props) => {
                //     // console.log("Props in renderOption:", props);
                //     return (
                //     <Group {...props} gap="xs" align="center">
                //         <Text size="xs" fw={700}>
                //         {" "}
                //         <IconPill
                //             size="1rem"
                //             color="#94ADA7"
                //             style={{ verticalAlign: "middle", marginRight: "3" }}
                //         />{" "}
                //         {props.option.medicament.denomination}
                //         </Text>
                //         <Text size="xs" fs="italic">
                //         {props.option.medicament.dci} ({props.option.medicament.voie})
                //         </Text>
                //     </Group>
                //     );
                // }}
              />

              {/* ---- Montant total & paiement ---- */}

              <Button
                radius="12px"
                size="sm"
                mt="15.5px"
                onClick={() => toggleAccidentTiers()}
                color="#94ADA7"
                variant="filled"
              >
                Montant Total: {montantTotal} €
              </Button>

              <Group>
                <Checkbox
                  variant="filled"
                  radius="12px"
                  color="#94ADA7"
                  iconColor="#fff"
                  label={
                    <Text ta="start" c="#94ADA7" fw={700} size="sm">
                      L’assuré(e) n’a pas payé la part AMO
                    </Text>
                  }
                  checked={TiersAMO}
                  onChange={(event) => setTiersAMO(event.currentTarget.checked)}
                />
                <Checkbox
                  variant="filled"
                  radius="12px"
                  color="#94ADA7"
                  iconColor="#fff"
                  label={
                    <Text ta="start" c="#94ADA7" fw={700} size="sm">
                      L’assuré(e) n’a pas payé la part AMC
                    </Text>
                  }
                  checked={TiersAMC}
                  onChange={(event) => setTiersAMC(event.currentTarget.checked)}
                />
              </Group>
            </Stack>
          </Card>
        </Tabs.Panel>

        {/* =======================================
            Onglet Factures
        ========================================== */}
        <Tabs.Panel value="Factures">
          <Card
            className="dispositifMedicalCard"
            shadow="lg"
            radius="12px"
            p="md"
            withBorder
          >
            <Stack align="stretch" justify="flex-start" gap="xs">
              <Group position="apart">
                <Title size="h4">Factures</Title>
              </Group>
              {/* Contenu spécifique aux factures... */}
            </Stack>
          </Card>
        </Tabs.Panel>
      </Tabs>
    </Grid.Col>
  );
}
